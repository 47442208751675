


.aside-contact{
    width: 80%;
    margin: auto;
}

.article-contact{
    display: flex;
    width: 100%;
    margin: auto;
    align-items: center;
    justify-content: center;
    flex-wrap: wrap;
}
.article-contact div{
    margin: 20px;
}

.card-contact h4{
    font-size: x-large;
    margin-bottom: 0;
}
.card-contact ul{
    padding: 0;
    margin-top: 0;
}
.card-contact ul li{
    list-style: none;
    font-size: large;
    margin: 5px;
    cursor: pointer;
}

.card-contact ul li:hover{
    text-decoration: underline;
}

.card-contact a{
    color: var(--black-color);
}


.card-social-media {
    width: 80%;
    margin: auto;
    display: flex;
    justify-content: center; /* Centraliza os ícones horizontalmente */
    align-items: center; /* Centraliza os ícones verticalmente */
    flex-wrap: wrap;
}

.card-social-media span {
    display: flex;
    justify-content: center;
    align-items: center;
    color: var(--primary-color);
    margin: 20px;
    padding: 40px;
    width: 80px;
    height: 80px;
    border: 7px solid var(--primary-color);
    border-radius: 50%;
    transition: .5s;
}
.card-social-media a{
    color: var(--primary-color);
}
.card-social-media span i {
    font-size: 50px;
    cursor: pointer;
    transition: .2s;
}


.card-social-media span i:hover{
    font-size: 60px;
}


@media screen and (max-width: 1200px) {
    .aside-form{
        padding: 50px;
    }
}

@media screen and (max-width: 1050px) {
    .aside-form img{
        width: 100%;   
    }
    .aside-form{
        padding: 30px;
        
    }
}

@media screen and (max-width: 900px) {
    .aside-form img{
        display: none;
    }
    .aside-form{
        display: block;
        
    }
    .aside-form{
        padding: 20;
        
    }
    .aside-form article{
        margin: 20px;
    }
}