:root {
    --font-ssmall: 12px;
    --font-small: 14px;
    --font-medium: 18px;
    --font-large: 20px;
    --font-xlarge: 24px;
    --font-xxlarge: 32px;
    --font-xxxlarge: 39px;
    --font-xxxxlarge: 45px;

    --padding-sssmall: 3px;
    --padding-ssmall: 5px;
    --padding-small: 10px;
    --padding-medium: 15px;
    --padding-large: 20px;
    --padding-xlarge: 25px;
    --padding-xxlarge: 30px;

  }
  