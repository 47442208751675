.logo-carousel {
    overflow: hidden;
    width: 100%;
    background: #fff; /* Ajuste conforme necessário */
    padding: 20px 0; /* Ajuste conforme necessário */
}

.logo-track {
    display: flex;
    animation: scroll 20s linear infinite;
}

@keyframes scroll {
    from {
        transform: translateX(0);
    }
    to {
        transform: translateX(-50%);
    }
}

.logo-item {
    min-width: 200px; /* Ajuste conforme necessário */
    padding: 10px; /* Ajuste conforme necessário */
    margin: 10px;
}

.logo-item img {
    width: 100%;
    height: auto;
}