section{
    margin-top: 100px;
}

.who-we-are h3{
    margin-bottom: 30px;
}
.who-we-are,
.history{
    width: 80%;
    margin: auto;
    margin-bottom: 10px;
}

.who-we-are img{
    width: 100%;
}

.history{
    margin-top: 100px;
}
.history article{
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 20px;
}

.history article div {
    margin: 50px;
}

.history article article p,
.history article article img {
    flex: 1; 
    text-align: center; 
}

.article-card{
    width: 80%;
    margin: auto;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-wrap: wrap;
}

.article-card .card{
    width: 350px;
}


.mission-vision-value{
    background-image: url('../../assets/images/bg-image-code.png');
}

.mission-vision-value div{
    display: flex;
    text-align: center;
    color: var(--white-color);
    background-color: var(--background-color);
    padding: 30px 10% 30px 10%;
}

.mission-vision-value div hr{
    border: 3px solid var(--white-color);
    opacity: 1;
}

.mission-vision-value article{
    margin: 20px;
    width: 30%;
}

.mission-vision-value article ul {
    width: 150px;
    margin: auto; 

}

@media screen and (max-width: 900px){
    .mission-vision-value div{
        display: block;
    }
}

@media screen and (max-width: 1100px){
    .history img{
        display: none;
    }
    .history article{
        display: block;
    }
}


