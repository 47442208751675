section{
    margin: 50px auto 100px auto;
}

aside{
    margin-top: 100px;
}

h3{
    font-size: x-large;
    text-align: center;

}

p{
    font-size: large;
    text-align: center;
    margin-bottom: 30px;
}

.bg-image{
    background-image: url('../../assets/images/bg-image-code.png');
}

.bg-image article{
    padding: var(--padding-xlarge);
    color: var(--white-color);
    background-color: var(--background-color);

}

.aside-services {
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 5%;
}

.aside-services article {
    flex: 1; 
    text-align: center; 
}



.text-white{
    color: var(--white-color);
}


iframe{
    width: 80%;
    height: 500px;
}
.aside-call-man{
    display: flex;
    align-items: center;
    justify-content: center;
    padding-bottom: 0;
    margin-bottom: 0;
}

.aside-call-man article{
    flex: 1; 
    text-align: center;
}
.aside-call-man .itens-center{
    padding: 5%;
}

.aside-call-man img{
    margin-bottom: 0;
    padding-bottom: 0px;
}

@media screen and (max-width: 1200px) {
    .aside-services img{
        width: 80%;
        max-width: 600px;
    }
}

@media screen and (max-width: 800px) {
    .aside-services{
        display: block;
    }
}

@media screen and (max-width: 1000px) {
    .aside-call-man .aside-image{
        display: none;
    }

}
