@import '../../assets/styles/colors.css';
@import '../../assets/styles/sizes.css';

footer {
    background-color: var(--primary-color);
    color: var(--white-color);
    padding: 5%;
    display: flex;
    flex-direction: column;
    align-items: center;
}

footer .footer {
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-wrap: wrap;
    text-align: center;
}

footer .footer aside {
    flex: 1;
    margin: 10px;
}

footer .footer img {
    width: 250px;
}

footer .footer h3 {
    font-size: var(--font-xlarge);
    margin-bottom: 8px;
}

footer .footer ul {
    list-style: none;
    padding: 0;
    margin: 0;
}

footer .footer ul li {
    margin-bottom: 3px;
}

footer .footer a {
    cursor: pointer;
    color: var(--white-color);
}

footer .footer a:hover,
footer .footer .contact a:hover  {
    text-decoration: underline;
}



footer .footer .social-media {
    display: flex;
    justify-content: center; /* Center social media icons */
    margin-top: 10px; /* Add some spacing above the icons */
}

footer .footer .social-media li {
    margin-right: 15px;
}

footer .footer .bi {
    font-size: var(--font-large);
}

footer .copyright {
    text-align: center;
    margin-top: 20px;
}

/* Responsive adjustments */
@media screen and (max-width: 768px) {
    footer .footer {
        flex-direction: column;
        align-items: center;
    }
    
    footer .footer div {
        margin: 10px 0;
    }

    footer .copyright {
        margin-top: 10px;
    }
}
