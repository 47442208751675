.container{
    width: 100%;
}

.alert {
    padding: 20px;
    width: 100%;
    border-radius: 10px;
}

.alert-success{
    background-color: #3cb37224;
}

.alert-danger{
    background-color: #e70e0e6a;
}

.alert p {
    color: var(--white-color);
    font: medium;
}
