@import '../../assets/styles/colors.css';
@import '../../assets/styles/sizes.css';

.header {
    position: relative;
    overflow: hidden;
}

.header-100{
    height: 100vh;
}

.header-90{
    height: 90vh;
}

.header-80{
    height: 80vh;
}

.header-70{
    height: 70vh;
}

.header-60{
    height: 60vh;
}

.header-50{
    height: 50vh;
}

.video_background {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    object-fit: cover;
    z-index: -1;
}

.overlay {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
}

.top-bar {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: var(--padding-large);
}

.logo {
    width: 250px;
    margin-left: 5%;
}

.nav-menu {
    list-style-type: none;
    display: flex;
    gap: var(--padding-large);
    margin-right: 5%;
}

.nav-menu li {
    position: relative;
}

.nav-menu li a {
    text-decoration: none;
    color: white;
    font-size: var(--font-medium);
}

.nav-menu .dropdown-menu {
    width: auto;
    display: none;
    position: absolute;
    top: 100%;
    left: 0;
    background-color: #fff;
    border-radius: var(--padding-small);
    color: black;
    list-style-type: none;
    padding: var(--padding-ssmall);
    margin: 0;
    z-index: 1000;
}

.nav-menu li:hover .dropdown-menu {
    display: block;
}

.dropdown-menu li {
    padding: var(--padding-small) var(--padding-sssmall) var(--padding-small) var(--padding-sssmall);
}

.dropdown-menu li a {
    color: black;
    font-size: var(--font-small);
}

.container-header{
    margin-bottom: 50px;
}

.container-header p{
    margin: 0;
}

.center-content {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    text-align: center;
    color: white;
    flex-grow: 1;
}

.center-content h1 {
    font-size: var(--font-xxlarge);
    margin-bottom: 0;
}

.center-content p{
    font-size: var(--font-medium);
}



.menu-icon {
    cursor: pointer;
    display: none;
}

.menu-icon span {
    display: block;
    width: var(--font-xxlarge);
    height: 3px;
    margin-bottom: var(--padding-small);
    margin-right: var(--padding-xlarge);
    background-color: var(--white-color);
}

.sidebar {
    position: fixed;
    top: 0;
    right: -300px;
    width: 250px;
    height: 100%;
    background-color: var(--primary-color);
    transition: right .5s;
    display: flex;
    flex-direction: column;
    padding: var(--font-large);
    overflow-y: auto;
}

.sidebar.open {
    right: 0;
}


.sidebar ul {
    list-style: none;
    padding: 0;
    transition: 5s;
}

.sidebar ul.sub-items{
    margin-left: var(--padding-large);
    display: none;
    transition: .5s;
}

.sidebar ul.sub-items.open{
    display: block;
    transition: 5s;
}

.sidebar ul li {
    margin: var(--padding-small) 0;
}

.sidebar ul li a {
    color: var(--white-color);
    font-size: var(--font-xmedium);
    text-decoration: none;
}

.icon-service{
    display: inline-flex;
    height: 15px;
    margin-left: 10px;
}

.icon-service1,
.icon-service2{
    border: 1px solid var(--white-color);
    background-color: #fff;
    padding: 0;
    margin: 2.3px;
    transition: .5s;
}

.icon-service1{
    transform: rotate(-45deg);
}

.icon-service2{
    transform: rotate(45deg);
}

.icon-service1.open{
    transform: rotate(45deg);
}

.icon-service2.open{
    transform: rotate(-45deg);
}

@media screen and (max-width: 1000px) {
    .nav-menu {
        display: none;
    }

    .menu-icon {
        position: absolute;
        display: block;
        float: right;
        right: 20px;
        top: 70px;
        z-index: 2000;
        align-items: center;
        justify-content: center;
        
    }
    .menu-icon.open .menu-icon2{
        display: none;
    }

    .menu-icon.open .menu-icon1{
        transform: rotate(-45deg);
        margin: -3px;
    }

    .menu-icon.open .menu-icon3{
        transform: rotate(45deg);
        margin: -3px;
    }

    .menu-icon span {
        width: var(--font-xxlarge);
        height: 3px;
        background-color: var(--white-color);
        z-index: 1000;
        transition: .8s;
    }

}
