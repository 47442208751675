section{
    width: 100%;
}
.services-section {
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 40px;
    padding: 20px;
    margin: auto;
    width: 80%;
}
  
.services-section aside{
    width: 50%;
    margin-top: 0;
    margin: auto;
}
.services-list {
  display: flex;
  flex-direction: column;
  gap: 20px;
  width: 200px;
  margin: auto;
}

.service-item {
  display: flex;
  align-items: center;
}

.service-number {
  background-color: #123524;
  color: white;
  font-size: 24px;
  font-weight: bold;
  padding: 10px;
  border-radius: 5px;
  margin-right: 10px;
}

.service-text {
  font-size: 18px;
}
.phone-image{
  text-align: center;
}
.phone-image img {
  width: 300px;
}
  
@media screen and (max-width: 600px) {
  .services-section img{
    display: none;
  }
}