@import './colors.css';
@import './sizes.css';

body {
    overflow-x: hidden;
}

h2{
    text-align: center;
}


.button{
    padding: var(--padding-small) var(--padding-large);
    font-size: var(--font-large);
    border-radius: var(--padding-small);
    color: var(--white-color);
    cursor: pointer;
    text-decoration: none;
    margin-top: 20px;
}
.bt-secondary {
    background-color: var(--primary-color);
}
.bt-primary {
    border: var(--padding-sssmall) solid var(--white-color);
}

.itens-center{
    display: flex;
    flex-direction: column;
    align-items: center;
    text-align: center;
}

.background-primary{
    background-color: var(--primary-color);
}

.padding-30{
    padding: 30px;
}

.padding-50{
    padding: 50px;
}

.text-white{
    color: var(--white-color);
}

.display-flex {
    display: flex;
    justify-content: center;
    width: 90%;
    margin: auto;
    /* flex-wrap: wrap; */
}

.width-100{
    width: 100%;
}

.width-50 {
    width: 50%;
    margin: 50px;
}

.margin-50{
    margin: 50px;
}

.margin-top-40,
.margin-bottom-40{
    margin-bottom: 40px;
}

a.link {
    color: var(--white-color);
    text-decoration: none;    
}
.text {
    width: 100%;
    margin: 30px auto 30px auto;
}

.text-80{
    width: 80%;
}



li a {
    text-decoration: none;
}

.text-justify {
    text-align: justify;
}


.img {
    border-radius: 20px;
    z-index: 1000;
    box-shadow: 0px 10px 20px rgba(0, 0, 0, 0.15);
    transform: translateY(-10px); 
    transition: transform 0.3s ease, box-shadow 0.3s ease; 
    margin-top: 10px;
}

.img:hover {
    transform: translateY(-20px); 
    box-shadow: 0px 15px 30px rgba(0, 0, 0, 0.3); 
}


@media screen and (max-width: 1000px) {
    
    .display-block-1000{
        display: block;
        width: 100%;
    }

    .display-block-1000 .width-50{
        width: 80%;
        margin: auto;
        margin-top: 50px;
    }

    .display-block-1000 img{
        display: none;
    }

}