.card{
    width: 250px;
    border-radius: 10px;
    box-shadow: 0px 0px 5px 0px;
    margin: 50px;
}

.card img{
    width: 100%;
}
.card .card-header{ 
    margin-top: -20px;
    padding: 0;
}

.card .border-radius{ 
    border-top-left-radius: 20px;
    border-top-right-radius: 20px;
}
.card img{
    padding: 0px;
    margin: 0px;
}
.card .card-text,
.card .card-title{
    padding: 10px;
}

.card .card-text p,
.card .card-text h4{
    text-align: center;
    font-size: large;
    padding: 0;
    margin: 0;
}