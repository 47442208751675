@import '../../assets/styles/colors.css';

.stage {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 80%;
    margin: auto;
    flex-wrap: wrap;
}

.card-stage {
    margin: 20px;
    display: flex;
    flex-direction: column;
    align-items: center;
}

.circle {
    width: 180px;
    height: 180px;
    border: 10px solid var(--primary-color);
    border-radius: 50%;
    display: flex;
    align-items: center;
    justify-content: center;
    text-align: center;
    margin-bottom: 10px; /* Espaço entre o círculo e o triângulo */
}

.triangle {
    width: 0;
    height: 0;
    margin-top: -4px;
    border-top: 25px solid var(--primary-color);
    border-right: 25px solid transparent;
    border-left: 25px solid transparent;
}

.aside-stage .line {
    width: 100%;
    height: 15px;
    opacity: 1;
    background-color: var(--primary-color);
    
}
