
.aside-form{
    background-color: var(--primary-color);
    color: var(--white-color);
    display: flex;
    padding: 100px;
}

.aside-form h3{
    font-size: xx-large;
}

.aside-form a{
    font-size: large;
    cursor: pointer;
} 

.aside-form a:hover{
    text-decoration: underline;
}


.aside-form article{
    margin: 50px;
    width: 60%;
}

.aside-form .article-image{
    width: 70%;
    align-items: center;
    justify-content: center;
    justify-items: center;
    align-items: center;
}
.aside-form img{
    width: 400px;
    margin: auto;
    margin-bottom: -3px;
    padding-bottom: 0;
    border: 5px solid var(--white-color);
    border-top: none;
    border-left: none;

}

.article-form form{

    background-color: transparent;
    border: 5px solid var(--white-color);
    border-radius: 10px;
    padding: 20px;
}



.article-form .form label,
.article-form .form span,
.article-form .form .input{
    display: block;
    width: 95%;
    margin: auto;
    font-size: large;
}

.article-form .form .input{
    padding: 10px;
    background-color: transparent;
    border-radius: 10px;
    border: 3px solid var(--white-color);
    margin-bottom: 10px;
    color: var(--white-color);
}

.article-form .form span{
    font-size: medium;
}

.article-form .form {
    position: relative;
    user-select:none;
}

.article-form .form .checkbox {
    position: relative;
    user-select: none;
    margin-bottom: 20px;
    margin-left: 15px;
}

.article-form .form .checkbox input {
    opacity: 0; /* Changed from visibility: hidden */
    position: absolute; /* To ensure it doesn't take up space */
    left: 0;
    width: 20px;
    height: 20px;
    z-index: 2; /* To make it focusable */
    cursor: pointer;
}

.article-form .form .checkbox label {
    display: flex;
    align-items: center;
    color: #fff;
    padding-left: 30px;
    cursor: pointer;
    position: relative;
    z-index: 1; /* To make sure the label is below the checkbox */
}

.article-form .form .checkbox label::before {
    position: absolute;
    left: 0;
    border: 3px solid var(--white-color);
    content: "";
    width: 20px;
    height: 20px;
    border-radius: 3px;
    transition: all .2s ease-in-out;
}

.article-form .form .checkbox input:checked ~ label::before {
    transform: rotate(30deg);
    border-left-color: transparent;
    border-top-color: transparent;
    border-radius: 0;
    width: 10px;
    height: 20px;
    left: 10px;
    margin-bottom: 10px;
    margin-right: 5px;
}

.phone-input {
    display: flex;
    align-items: center;
}

.phone-input select {
    margin-right: 10px;
    max-width: 80px; /* Ajuste conforme necessário */
}
.phone-input select option {
    background-color: var(--primary-color);
}

.phone-input input[type="tel"] {
    flex: 1;
}

/* styles.css */
.dropdown-scrollable {
    max-height: 200px;
    overflow-y: auto;  
}



@media screen and (max-width: 1200px) {
    .aside-form{
        padding: 50px;
    }
}

@media screen and (max-width: 1050px) {
    .aside-form img{
        width: 100%;   
    }
    .aside-form{
        padding: 30px;
        
    }
}

@media screen and (max-width: 900px) {
    .aside-form img{
        display: none;
    }
    .aside-form{
        display: block;
        
    }
    .aside-form{
        padding: 20;
        
    }
    .aside-form article{
        margin: 20px;
        width: 90%;
    }
    .aside-form .article-image {
        width: 100%;
    }
}